@import 'App.scss';

.dialog-iframe {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-Items: center;
  justify-content: center;

  .dialog-iframe-content {
    position: relative;
    padding: 50px 0px;
    min-height: 200px;
    background: #FFFFFF;
    box-shadow: 0 3pt 6pt #00000047;
    border-radius: 3pt;
    min-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: $breakpoint-mobile) {
      width: 97%;
      min-width: 0;
      flex-direction: unset;
      display: flex;
    }

    .close-icon {
      height: 30px;
      width: 30px;
    }
  }
}
