.trading-box {
  width: 100%;
  height: 100%;
  overflow: auto;

  .nd-widget-container {
    padding: 0!important;
  }

  .text-box {
    -webkit-padding-start: 2rem;
            padding-inline-start: 2rem;
    -webkit-padding-end: 2rem;
            padding-inline-end: 2rem;
    padding-top: 2rem;

    .title {
      font-size: 18px;
      font-weight: 600;
    }

    .text {
      font-size: 13px;
    }
  }

  .nd-widget-container {
    height: 100%;
    padding: 0;
  }

  .tabs-container {
    height: 100%;
  }
}
