@import '../App.scss';

.kyc-wrap {
  width: 100%;

  .kyc-wrap-inner {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .onboard {
      @media (max-width: $breakpoint-mobile) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .submit-doc {
        cursor: pointer;
      }

      .title {
        font-size: 14px;
        font-weight: 200;
      }

      .doc-type {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;

        label {
          padding: 5px 5px 5px 0px;
          display: flex;
          align-items: center;
          font-size: 15px;

          .MuiRadio-colorSecondary {
            color: #5BBC54;
          }
        }
      }

      .file-upload {
        width: 100%;
        display: flex;

        @media (max-width: $breakpoint-mobile) {
          flex-direction: column;
        }

        input[type="file"] {
          display: none;
        }

        .custom-file-upload {
          border: 1px solid #D5E05B;
          border-radius: 5px;
          padding: 1rem 3rem;
          margin: 1rem 0 0.25rem 0;
          cursor: pointer;
          width: 100%;
          font-size: 12px;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;

          @media (max-width: $breakpoint-mobile) {
            padding: 1rem 0;
          }

          .upload-icon {
            -webkit-padding-end: 0.5rem;
                    padding-inline-end: 0.5rem;
            font-size: 20px;
          }
        }

        .custom-file-upload.disabled {
          cursor: initial;
        }

        .dropzone {
          height: 100px;
          border: 1px solid black;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .title {
        display: flex;
        margin-top: 0;
        align-items: center;
        font-size: 14px;

        .step-counter {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          border-radius: 5px;
          -webkit-margin-end: 10px;
                  margin-inline-end: 10px;
          background-color: #ececec;
        }
      }

      .green-cta {
        padding: 10px 10px;
        width: 140px;
      }

      .file-note {
        font-size: 12px;
        margin: 0 0 1rem 0;
      }

      .centered-div {
        display: flex;
        justify-content: center;
      }
    }

    .uploaded-file-list {
      max-height: 155px;
      overflow-y: auto;

      .item {
        border: 1px solid #f3ff6c;
        display: flex;
        align-items: center;
        padding: 3px;
        margin-bottom: 2px;

        .preview {
          flex: 0 0 70px;
        }

        .name {
          flex: 1;
          display: flex;
          justify-content: center;
          flex-direction: column;
          font-size: 12px;
        }

        .status {
          flex: 0 0 100px;
          font-size: 13px;
          -webkit-margin-end: 10px;
                  margin-inline-end: 10px;
          text-align: center;
          border: 1px solid rgb(211, 211, 0);
          border-radius: 5px;
          color: rgb(211, 211, 0);
          padding: 4px;

          &.Approved {
            border-color: green;
            color: green;
          }

          &.Rejected {
            border-color: red;
            color: red;
          }

          &.Draft {
            border: none;
          }
        }

        .rejected-with-reason-div {
          text-align: center;
          flex: 0 0 140px;
        }

        .rejection-reason {
          font-size: 10px;
          color: red
        }

        .button {
          cursor: pointer;
          flex: 0 0 24px;
        }
      }
    }
  }
}

.onboarding-otherdoc {
  width: 95%;

  .uploaded-file-list {
    max-height: 155px;
    overflow-y: auto;

    .item {
      border: 1px solid #f3ff6c;
      display: flex;
      align-items: center;
      padding: 3px;
      margin-bottom: 2px;

      .preview {
        flex: 0 0 70px;
      }

      .name {
        flex: 1;
        display: flex;
        justify-content: center;
        flex-direction: column;
        font-size: 12px;
      }

      .status {
        flex: 0 0 100px;
        font-size: 13px;
        -webkit-margin-end: 10px;
                margin-inline-end: 10px;
        text-align: center;
        border: 1px solid rgb(211, 211, 0);
        border-radius: 5px;
        color: rgb(211, 211, 0);
        padding: 4px;

        &.Approved {
          border-color: green;
          color: green;
        }

        &.Rejected {
          border-color: red;
          color: red;
        }

        &.Draft {
          border: none;
        }
      }

      .rejected-with-reason-div {
        text-align: center;
        flex: 0 0 140px;
      }

      .rejection-reason {
        font-size: 10px;
        color: red
      }

      .button {
        cursor: pointer;
        flex: 0 0 24px;
      }
    }
  }
}

.onboarding-contract {
  .title {
    margin: 0;
  }

  .campaign-descrption {
    display: flex;
    margin: 1rem 0;
    align-items: center;

    .icon {
      -webkit-margin-end: 10px;
      margin-inline-end: 10px;
      height: 25px;
      width: 25px;
      color: #ffbc00;
    }

    .text {
      font-size: 13px;
      margin: 0;
      width: 100%;
    }
  }

  .campaign-terms {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .terms-input {
      -webkit-margin-end: 10px;
              margin-inline-end: 10px;
    }

    .checkbox-label {
      font-size: 13px;
      margin: 5px 0;

      .terms-link {
        text-decoration: none;
        color: $blue-color;
      }
    }
  }
}

.onboarding-clientPresets {
  .field-splitter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .field-group {
      width: 45%;
    }

    .field-label {
      font-size: 14px;
      font-weight: 600;
      color: #000;
      margin-bottom: 0.25rem;
    }
  
    .selector {
      border: none;
      font-size: 16px;
      background-color: transparent;
      color: #000;
      border: 1px solid #0000004d;
      border-radius: 5px;
      padding: 0.75rem;
      outline: none;
      transition: color 1s, border-color 1s, border-width 0.2s, border-radius 1s;
      cursor: pointer;
    }
  }
}