@import "../../App.scss";

.link-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;
  max-width: 600px;
  box-shadow: 0 0 16px #00000029;
  border-radius: 5px;
  transition: all 0.5s;
  overflow: hidden;
  width: 100%;
  max-height: 1000px;

  .onboarding-link {
    color: #000;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 12px;
    width: 100%;
    min-height: 100px;
    outline: none;
    cursor: pointer;
    border-bottom: 1px solid #00000029;
    transition: border 1s;

    .icon-title {
      display: flex;
      align-items: center;
      -webkit-padding-start: 1rem;
      padding-inline-start: 1rem;
      position: relative;

      .help-box {
        position: absolute;
        top: 0px;
        inset-inline-start: 290px;
        padding: 10px;
        min-width: 200px;
        background-color: #d2d738d7;
        box-shadow: 0px 0px 15px 2px #33401a87;
        border-radius: 5px;
        transition: opacity 0.6s ease, visibility 0.6s ease;
        visibility: visible;
        z-index: 10;

        @media (max-width: $breakpoint-mobile) {
          inset-inline-start: 40px;
        }
      }

      .help-box.hide-help-box {
        opacity: 0;
        visibility: hidden;
      }

      .link-title {
        font-size: 17px;
        font-weight: 600;
        margin: 0;
        display: flex;
        align-items: center;

        .info-icon {
          -webkit-padding-start: 10px;
          padding-inline-start: 10px;
          height: 25px;
          width: 25px;
          color: #ffbc00;
          transition: opacity 0.6s ease, visibility 0.6s ease;
          visibility: visible;

          &.hide-info-icon {
            opacity: 0;
            visibility: hidden;
          }

          @media (max-width: $breakpoint-mobile) {
            -webkit-padding-start: 150px;
            padding-inline-start: 150px;
          }
        }
      }

      .icon {
        height: 50px;
        width: auto;
        -webkit-margin-end: 30px;
                margin-inline-end: 30px;

        @media (max-width: 480px) {
          -webkit-margin-end: 5px;
          margin-inline-end: 5px;
        }
      }

      .with-note {
        flex-direction: column;
        align-items: flex-start;

        .questionnaire-note {
          font-size: 10px;
        }
      }
    }

    .rejected-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .reason-text {
        -webkit-margin-end: 1rem;
        margin-inline-end: 1rem;
        font-size: 10px;
        font-weight: bold;
        color: #ff0000;
        align-items: center;
        margin-top: 5px;
        margin-bottom: 0;
      }
    }

    .status-text {
      padding: 10px 40px;
      border-radius: 5px;
      border: 2px solid;
      text-transform: uppercase;
      font-weight: 500;
      -webkit-margin-end: 1rem;
      margin-inline-end: 1rem;
      width: 100px;
      text-align: center;
      font-size: 13px;

      @media (max-width: 480px) {
        padding: 10px 12px !important;
        min-width: 128px;
      }

      &.status-approved {
        border-color: #50b848;
        color: #50b848;
      }

      &.status-pending {
        border-color: #ffbc00;
        color: #ffbc00;
      }

      &.status-rejected {
        border-color: #ff0000;
        color: #ff0000;
      }

      .popover {
        pointer-events: "none";
      }
    }

  }

  &.closed-link {
    max-height: 100px;

    .onboarding-link {
      border-bottom: 1px solid transparent;
    }
  }

  &.disabled {
    .onboarding-link {
      cursor: initial;
    }
  }

  &.inactive {
    background-color: #f3f3f3;

    .onboarding-link {
      cursor: default;

      .icon-title {
        color: #9a9a9a;

        .icon {
          -webkit-filter: saturate(0%);
          filter: saturate(0%);
        }
      }

      .arrow-icon {
        color: #9a9a9a;
      }
    }
  }
}
