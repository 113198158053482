$bg-dark-mode: #000;
$dashboard-container-dark-mode: #1e1e1e;
$dashboard-widgets-dark-mode: #272727;
$dashboard-widgets-border-dark-mode: #70707042;
$dashboard-side-menu-icons-dark-mode: #1d1c1c;
$dashboard-popup-blur-drop: #00000054;
$white-text: #ffff;
$progress-color: #4cd964;
$color_checkbox_success: #4cd964;
$color_checkbox_default: #8e8e93;
$color-form-placeholder: #c1c1c1;
$transition: 0.3s cubic-bezier(0.95, 0.05, 0.795, 0.035);
$width: 2em;
$height: calc(var($width) / 2);

body.dark-mode {
  color: $white-text;

  .nd-widgets-area,
  .nd-mobile-header,
  .bm-menu {
    background-color: $dashboard-container-dark-mode;
  }

  .cta,
  .green-cta,
  .green-cta-quick-fix,
  .white-cta {
    &:hover {
      box-shadow: #474747 0 0px 10px 3px;
    }
  }

  .root-dashboard {
    background-color: $dashboard-container-dark-mode;
  }

  .risk-warning-body,
  .nd-header,
  .nd-side-menu-item.active {
    background-color: $dashboard-side-menu-icons-dark-mode;
    box-shadow: 1px 1px 1px $dashboard-widgets-border-dark-mode;
  }

  .outer-div {
    background-color: $dashboard-widgets-dark-mode;
    border: 1px solid $dashboard-widgets-border-dark-mode;
  }

  .MuiGrid-root,
  .MuiGrid-container,
  .MuiGrid-spacing-xs-2,
  .css-12n0uxm-MuiGrid-root,
  .method-box,
  .link-boxed,
  .nd-side-menu,
  .your-partner-link,
  .marketing-carousel,
  .market-analysis,
  .campaign-request,
  .client-presets,
  .callback-inner,
  .field,
  .optionsSelector,
  .support-inner,
  .method-div,
  .my-profile,
  .my-profile.callback,
  .callback-inner,
  .callback-form,
  .field-group,
  .PhoneInputInput,
  .input-label,
  .MuiPopover-paper,
  .MuiDialog-paper,
  .dialog .dialog-content {
    background-color: $dashboard-widgets-dark-mode;
  }

  .vertical-divider {
    border-right: 1px solid $color_checkbox_default;
  }

  .nd-side-menu .nd-side-menu-item.active .nd-name-side-menu,
  .nd-name,
  .MuiDataGrid-columnHeaderTitle,
  .css-cc8tf1,
  .MuiTablePagination-displayedRows,
  .password-reset-link,
  .css-1chpzqh {
    color: $white-text !important;
  }

  .blocker {
    .description {
      color: $white-text;
    }
  }

  //notifications popover & modal
  .panel {
  .date-field,
  .title-field-unread,
  .description-field-unread,
  .no-notifications-msg,
  .panel-title {
    color: $white-text;
  }
  .title-field-read,
  .see-more-text,
  .MuiPaginationItem-root,
  .description-field {
    color: $color-form-placeholder;
  }
  .MuiPaginationItem-page.Mui-selected{
    background-color: green;
  }
  }

  //withdrawals info box
  .info-text {
    color: $bg-dark-mode;
  }

  .singleBannerOptionsInner {
    background-color: $dashboard-widgets-dark-mode;
    border: 1px solid $white-text;
  }

  .dialog .dialog-content {
    border: 1px solid $white-text;
  }

  .widget {
    border: 1px solid $dashboard-widgets-border-dark-mode;
  }

  .column-2 {
  }

  //lockedModal
  .lockedModal {
    .lockedPop {
      .innerLockedPop {
        background-color: $dashboard-widgets-dark-mode;
        border: 1px solid $white-text;

        .title {
          color: $white-text;
        }

        .text {
          color: $white-text;
        }
      }
    }
  }
  .blocker {
    .title {
      color: $white-text;
    }
  }
  //QRCODE
  .qrCode-Modal {
    .qrCode-Modal-inner {
      background-color: $dashboard-widgets-dark-mode;
      border: 1px solid $white-text;
    }
  }

  //IDLE MODAL
  .modal {
    color: $white-text;

    .modal__container {
      background-color: $dashboard-widgets-dark-mode;

      .modal__body {
        background-color: $dashboard-widgets-dark-mode;

        .modal__body-container {
          background-color: $dashboard-widgets-dark-mode;

          .modal-close {
            background-color: $dashboard-widgets-dark-mode;
          }

          .modal__content {
            background-color: $dashboard-widgets-dark-mode;
            color: $white-text;
          }
        }
      }
    }
  }

  //Withdrawal Page
      .nav-link {
      .text{
        color: $white-text;
      }
    }
  //withdraw boxes
  .method-box,
  .bank-details-btn {
    color: $white-text;
  }

  //withdraw forms
  .withdraw-form {
    .field {
      color: $white-text;
      border-color: $white-text;
    }

    .field:focus + .input-label,
    .field:not(:placeholder-shown) + .input-label {
      background-color: $dashboard-widgets-dark-mode;
    }
  }

  //Contact Us Form
  .support-form-box {
    .support-form {
      .field-group {
        border: unset;

        .field {
          border: 1px solid $white-text !important;
          color: $white-text !important;
        }

        .input-label {
          background-color: $dashboard-widgets-dark-mode !important;
        }
      }
    }
  }

  //Support Forms
  .support {
    .support-inner {
      .support-form {
        .field-group {
          border: unset;
          color: $white-text !important;
          .field {
            border: 1px solid $white-text !important;
            color: $white-text !important;
          }
        }

        .field:focus + .input-label,
        .field:not(:placeholder-shown) + .input-label {
          background-color: $dashboard-widgets-dark-mode;
        }
      }
    }
  }

  //Language Drop Down
  .MuiMenu-paper {
    background-color: $dashboard-widgets-dark-mode !important;
    color: $white-text !important;
  }

  //Login Forms
  .login {
    .login-inner {
      background-color: $dashboard-widgets-dark-mode;

      .login-form {
        .field-group {
          border: unset;
          color: $white-text;
          .field,
          .passField {
            border: 1px solid $white-text;
            color: $white-text;
            background-color: $dashboard-widgets-dark-mode;
          }
          .input-label {
            background-color: $dashboard-widgets-dark-mode !important;
          }
        }
      }
    }
  }

  //Register Forms
  .register {
    .register-inner {
      background-color: $dashboard-widgets-dark-mode;

      .register-form {
        .field-group {
          border: unset;
          color: $white-text;
          .field,
          .passField,
          .PhoneInputInput {
            border: 1px solid $white-text;
            color: $white-text;
            background-color: $dashboard-widgets-dark-mode;
          }

          .input-label,
          .country-input-label {
            background-color: $dashboard-widgets-dark-mode !important;
          }
        }
      }
    }
  }

  //Verfy-Email Forms
  .verify-email {
    .verify-email-inner {
      background-color: $dashboard-widgets-dark-mode;
      color: $white-text;
      .verify-email-form {
        .react-code-input {
          border: unset;
          color: $white-text;
          input {
            border: 1px solid $white-text;
            color: $white-text !important;
            background-color: $dashboard-widgets-dark-mode !important;
          }
        }
        .resend {
          color: $white-text;
        }
      }
    }
  }
  //Questionnaire Forms
  .questionnaire {
    .topRow,
    .title,
    .css-1s2u09g-control {
      background-color: $dashboard-widgets-dark-mode;
      input {
        border: unset;
      }
    }

    label,
    .css-26l3qy-menu {
      color: $white-text !important;
      background-color: $dashboard-widgets-dark-mode;
    }
    .css-26l3qy-menu > div > div:hover {
      background-color: #4b4b4b;
    }
    .css-26l3qy-menu > div > div.active {
      background-color: #4b4b4b;
    }
    .css-26l3qy-menu > div > div:focus-within {
      background-color: #4b4b4b;
    }
    #services-website-http,
    #regulator-website-http {
      border: 1px solid $white-text;
      background-color: $dashboard-widgets-dark-mode;
      color: $white-text;
    }
    .questionnaire-inner {
      background-color: $dashboard-widgets-dark-mode;

      .form-content {
        div,
        .options-container {
          color: $white-text;
        }
      }
      input {
        border: 1px solid $white-text;
        color: $white-text;
        background-color: $dashboard-widgets-dark-mode;
      }

      .input-label,
      .country-input-label {
        background-color: $dashboard-widgets-dark-mode;
      }
    }
  }

  .menu {
    .menu-inner {
      .menu-items {
        .green-cta,
        a {
          padding: 10px;
          width: 120px;
          font-size: 14px;
          background-color: #d5e05b;
          color: $bg-dark-mode !important;
        }
        .white-cta,
        a {
          background-color: unset;
          color: white !important;
        }
      }
    }
  }

  //User-Data Forms
  .user-data {
    .user-data-inner {
      background-color: $dashboard-widgets-dark-mode;
      .socials-title {
        color: $white-text;
      }
      .inactive {
        background-color: #4e4e4e;
      }
      .arrow-icon {
        -webkit-filter: invert(1);
        filter: invert(1);
      }
    }
    .progress-digit {
      border-color: $progress-color;
      color: $progress-color;
    }
    .progress-label {
      color: $progress-color;
    }
    input,
    select,
    .time,
    .field {
      color: $white-text;
      border-color: $white-text !important;
    }
    .splitter {
      background-color: $progress-color;
    }
    .input-label {
      background-color: $dashboard-widgets-dark-mode !important;
    }
  }
  // background of darkmode
  .screen-box {
    background-image: url("../media/images/backgrounds/kyc-background-darkmode.svg") !important;
  }

  //Change Password
  .change-password {
    .change-password-inner {
      background-color: $dashboard-widgets-dark-mode;

      .change-password-form {
        .field-group {
          border: unset;
          color: $white-text !important;
          .field,
          .passField {
            border: 1px solid $white-text;
            color: $white-text;
            background-color: $dashboard-widgets-dark-mode;
            input {
              color: $white-text;
              -webkit-box-shadow: 0 0 0 30px $dashboard-widgets-dark-mode inset;
            }
          }

          .input-label,
          .country-input-label {
            background-color: $dashboard-widgets-dark-mode !important;
          }
        }
      }
    }
  }
  // for the settings wheel
  .settings-link {
    color: $white-text;
  }
  // for autofill in darkmode autofill
  input:-webkit-autofill,
  .field:-webkit-autofill {
    color: $white-text;
    background-color: $dashboard-widgets-dark-mode !important;
    box-shadow: 0 0 0 30px $dashboard-widgets-dark-mode inset !important;
    color: $white-text;
    -webkit-text-fill-color: $white-text !important;
  }
  .footer {
    .footer-inner {
      .text {
        color: $white-text;
      }
    }
  }
  //clubs
  .club-promo-container {
    .club-promo-box {
      .club-boxes {
        ul.empty-circles-Standard,
        ul.empty-circles-Premium,
        ul.empty-circles-Vip {
          .paragraphText {
            color: $white-text;
          }
        }
      }
    }
  }

  .MuiInputLabel-outlined {
    color: $white-text;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid white;
  }

  .profile-page {
    .link-container {
      .nav-link {
        color: $white-text;
      }
    }
    .MuiInputBase-input,
    .MuiOutlinedInput-input,
    .MuiInputBase-inputAdornedEnd,
    .MuiOutlinedInput-inputAdornedEnd {
      background-color: $dashboard-widgets-dark-mode;
    }
  }

  //additional fix of promotion sectiontwo
  ul.empty-circles-club {
    .paragraphClubText {
      color: $white-text;
    }
  }

  //promotions
  .clubs-container {
    .club-members-container {
      .club-members-boxes {
        background-color: $dashboard-widgets-dark-mode;

        .text-container {
          .title-club,
          .subtitle-club-black-box {
            color: $white-text;
          }
        }
      }
    }
  }

  //Call back Forms
  .callback {
    .callback-inner {
      border: 1px solid $white-text;

      .callback-form {
        .field-group {
          border: unset;

          .PhoneInputInput,
          .field,
          .selector {
            color: $white-text;
            border: 1px solid $white-text;
          }

          .field:focus + .input-label,
          .field:not(:placeholder-shown) + .input-label {
            background-color: $dashboard-widgets-dark-mode;
          }
        }
      }
    }
  }

  //Products
  //Mobile App Section
  .mobile-app {
    .row-container {
      .bullet-text {
        color: $white-text;
      }
    }
  }

  .bullet-text {
    color: $white-text;
  }

  //Platforms
  .platforms {
    .box {
      background-color: $dashboard-widgets-dark-mode;

      .bullet-text {
        color: $white-text;
      }
    }

    .metatrader-container {
      .column,
      .left {
        background-color: $dashboard-widgets-dark-mode;

        .empty-circles {
          .bullet-text {
            color: $white-text;
          }
        }
      }
    }
  }

  .optionsSelector {
    border: 1px solid $white-text;
    color: $white-text;
    border-radius: 5px;
  }

  .field-label,
	//.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root,
	//.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root,
  .MuiOutlinedInput-input,
  .MuiInputLabel-outlined.MuiInputLabel-shrink,
  .title-dark,
  .MuiSvgIcon-root,
  .css-1jbbcbn-MuiDataGrid-columnHeaderTitle,
  .nd-name-side-menu,
  .arrow-icon,
  .MuiInputBase-root,
  .optionsSelector,
  .link-title,
  .css-30pzqk-MuiDataGrid-root, .MuiDataGrid-cell--textLeft, .css-30pzqk-MuiDataGrid-root,
  .MuiDataGrid-cell--textCenter, .css-levciy-MuiTablePagination-displayedRows,
  .cancelTransactionButton {
    color: $white-text;
  }

  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper,
  .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
    color: $color-form-placeholder;
  }

  .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    border-color: $color-form-placeholder;
  }

  .close-icon {
    -webkit-filter: invert(1);
    filter: invert(1);
  }

  .marketing-carousel .slideshow .slideshowDot {
    background-color: $white-text;
  }

  //communication page
  .socials-container-component {
    .comm-options-form {
      .social-input-box {
        .field-group {
          .field {
            border: 1px solid $white-text;
            color: $white-text;
          }

          .disabled-text {
            color: $color_checkbox_default;
          }

          .field:focus + .input-label,
          .field:not(:placeholder-shown) + .input-label {
            background-color: $dashboard-widgets-dark-mode;
          }
        }
      }
    }
  }

  //virtualTour
  .backgroundColors {
    background-color: #1d1c1c;
  }

  //Footer
  .footer-dashboard {
    background-color: $dashboard-widgets-dark-mode;

    .risk-warning-dashboard {
      background-color: $dashboard-widgets-dark-mode;
      border-top: unset;

      .risk-warning-para {
        color: $color-form-placeholder;
      }
    }
  }

  //virtualTourPopup
  .popup {
    background-color: $dashboard-popup-blur-drop;

    .popup-inner {
      background-color: $dashboard-widgets-dark-mode;
    }
  }

  .display {
    box-sizing: border-box;
    position: absolute;
    transform: translate(-50%, -50%);

    .text-Toggle {
      font-size: 10px;
    }
  }

  .cookie-box {
    .cookie-popup {
      background-color: $dashboard-container-dark-mode;
    }
  }

  .toggle {
    .toggle-control {
      transition: $transition;
      width: $width;
      height: $height;
      display: block;
      border: 2px solid $color_checkbox_default;
      border-radius: $height;
      background-color: rgba(black, 0.06);
      position: relative;

      &:after {
        transition: $transition;
        content: "";
        width: calc($width/2);
        height: $height;
        display: block;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 0 1px 2px rgba(black, 0.4), 0 3px 2px rgba(black, 0.4);
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    input {
      display: none;

      &:checked + .toggle-control {
        border-color: $color_checkbox_success;
        background-color: $color_checkbox_success;

        &:after {
          left: calc($width/2);
        }
      }
    }

    .toggle_input {
    }
  }

  .side-menu-toggle {
    padding: 10px 10px;
  }

  //Change Password Profile
  .profile-section-content {
    .form {
      .input {
        padding: 10px;
        background-color: $dashboard-widgets-dark-mode;
        color: $white-text;
        border-radius: 5px;
      }
    }
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.toogle-switch-container {
  display: flex;
  justify-content: start;
  width: 100%;
  position: relative;
}

.change-password {
  .toogle-switch-container {
    top: -20px;
    left: 30px;
  }
}
