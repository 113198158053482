@import '../App.scss';

.verify-email {
  display: flex;
  justify-content: center;
  align-items: center;
  .toogle-switch-container{
    top:-20px !important;
    left:-30px !important;
  }
  .verify-email-inner {
    padding: 3rem 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 3px 6px #00000047;
    border-radius: 25px;
    margin-top: 3rem;
    margin-bottom: 3rem;
    text-align: center;
    width: auto;

    @media (max-width: $breakpoint-mobile) {
      width: 95%;
      margin: 1rem auto;
    }

    .title {
      font-size: 28px;
      margin: 0;
      padding-bottom: 1rem;
    }

    .subtitle {
      max-width: 350px;
    }

    .verify-email-form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 350px;

      @media (max-width: $breakpoint-mobile) {
        width: 100%;
      }

      .resend {
        background-color: transparent;
        border: none;
        color: #00000080;
        text-decoration: underline;
        margin-top: 1.5rem;
        outline: none;
        cursor: pointer;
      }

      .field-group {
        display: flex;
        flex-direction: column;
        margin: 0.5rem 0;
        width: 100%;
        position: relative;

        .field,
        .PhoneInputInput {
          padding: 0.7rem;
          border-radius: 5px;
          font-size: 16px;
          border: 1px solid #00000059;
          outline: none;

          &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
          }
        }

        .input-label {
          position: absolute;
          top: 0.8rem;
          inset-inline-start: 0.7rem;
          overflow: hidden;
          color: var(--colorSecond);
          font-size: 16px;
          text-overflow: ellipsis;
          white-space: nowrap;
          transition: all .2s;
          pointer-events: none
        }

        .field:focus+.input-label,
        .field:not(:placeholder-shown)+.input-label {
          top: -6px;
          inset-inline-start: 7px;
          font-size: 12px;
          background-color: #fff;
        }
      }

      .split-field {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .field-group {
          width: 45%;
        }
      }

      .submit {
        background-color: #D5E05B;
        color: #000;
        padding: 1rem 2rem;
        border: none;
        border-radius: 5px;
        margin: 1rem;
        font-size: 16px;
        text-transform: capitalize;
        outline: none;
        font-weight: 600;
        cursor: pointer;
        -webkit-appearance: none;
      }
    }
  }
}

