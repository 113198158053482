@import '../App.scss';

.partnership {
  .partnership-inner {
    padding: 2rem 0;
    border-top: 1px solid #00000033;

    .title {
      text-align: center;
      color: #000000;
      font-weight: 500;
      font-size: 28px;
      margin: 5rem 0;

      @media (max-width: $breakpoint-mobile) {
        font-size: 24px;
        margin: 2rem;
      }
    }

    .infographics {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      @media (max-width: $breakpoint-laptop) {
        justify-content: space-evenly;
      }

      @media (max-width: $breakpoint-mobile) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .infographic-box {
        width: 250px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        text-align: center;

        @media (max-width: $breakpoint-mobile) {
          width: 240px;
          margin: 1rem 0;
        }

        .icon {
          width: auto;
          height: 100px;
        }

        .title {
          font-size: 18px;
          font-weight: 600;
          text-transform: uppercase;
          margin: 1.5rem 0;
        }

        .text {
          font-size: 14px;
          font-weight: 500;
          margin: 0;
        }
      }
    }
  }
}