.cta-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .cta {
    font-family: 'Raleway', sans-serif !important;
    color: #000;
    cursor: pointer;
    border-radius: 5px;
    padding: 15px 30px;
    font-weight: 700;
    border: none;
    text-align: center;
    font-size: 14px;
    margin: 0.5rem 0;
    text-transform: capitalize;
    text-decoration: none;
    transition: box-shadow 0.5s, background-color 0.5s, width 0.5s;  
  
    &:hover {
      box-shadow: #00000080 0 0px 7px 0px;

      .arrow-icon {
        padding-inline-start: 30px;
      }
    }
  }
  
  .cta-warning {
    padding: 0;
    margin: 0;
    font-size: 10px;
    mix-blend-mode: difference;
    color: white;
  }
}