.lockedModal {
  height: 100%;
  width: 100%;

  .lockedPop {
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: #ffffff54;
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.6s ease, visibility 0.6s ease;
    visibility: visible;
  
    .innerLockedPop {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 3rem;
      background-color: #fff;
      box-shadow: 0 3pt 6pt #00000047;
      border-radius: 5pt;
      margin-top: 3rem;
      margin-bottom: 3rem;
      max-width: 500px;
      text-align: center;

      .title {
        font-size: 24px;
        max-width: 460px;
        margin-bottom: 2rem;
      }

      .icon {
        width: 110px;
        margin-bottom: 50px;
      }
  
      .text {
        font-size: 18px;
        max-width: 400px;
        margin-bottom: 1rem;
      }
    }
  }
}