@import '../App.scss';

.register {
  display: flex;
  justify-content: center;
  align-items: center;
  .toogle-switch-container{
    left:-30px;
    top:-20px;
  }
  .register-inner {
    padding: 3rem 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 3px 6px #00000047;
    border-radius: 25px;
    margin-top: 3rem;
    margin-bottom: 3rem;
    text-align: center;
    width: auto;

    .title {
      font-size: 28px;
      margin: 0;
      font-weight: 600;
      padding-bottom: 1rem;
    }

    .subtitle {
      text-align: center;
      font-size: 14px;
      margin-top: 0;
      margin-bottom: 2rem;
    }

    .register-form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 350px;

      @media (max-width: $breakpoint-mobile) {
        width: 90%;
      }

      .field-group {
        display: flex;
        flex-direction: column;
        margin: 0.5rem 0;
        width: 100%;
        position: relative;

        input:-internal-autofill-selected {
          -webkit-box-shadow: 0 0 0 30px white inset;
        }

        .PhoneInput {
          width: 100%;

          .PhoneInputCountrySelect {
            inset-inline-start: -12px;
            width: 40px;
          }

          .PhoneInputCountrySelectArrow {
            display: none;
          }
        }

        .passField {
          .MuiInputBase-input {
            padding: 0;
          }

          .passwordEye {
            padding: 0;
          }
        }

        .field,
        .passField,
        .PhoneInputInput {
          padding: 0.7rem;
          border-radius: 5px;
          font-size: 16px;
          border: 1px solid #00000059;
          outline: none;
          background-color: transparent;
          -webkit-appearance: none;

          &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 30px white inset;
          }
        }

        .input-label {
          position: absolute;
          top: 0.8rem;
          inset-inline-start: 0.7rem;
          overflow: hidden;
          color: var(--colorSecond);
          font-size: 16px;
          text-overflow: ellipsis;
          white-space: nowrap;
          transition: all .2s;
          pointer-events: none
        }

        .field:focus+.input-label,
        .field:not(:placeholder-shown)+.input-label,
        .passField:focus-within ~ .input-label,
        .input-label.passFieldFilled {
          top: -8px;
          padding: 0 2px;
          inset-inline-start: 7px;
          font-size: 12px;
          background-color: #fff;
        }       

        .country-input-label {
          position: absolute;
          top: 0.8rem;
          inset-inline-start: 0.7rem;
          overflow: hidden;
          color: var(--colorSecond);
          font-size: 16px;
          text-overflow: ellipsis;
          white-space: nowrap;
          transition: all .2s;
          pointer-events: none
        }

        .field:valid+.country-input-label {
          top: -8px;
          padding: 0 2px;
          inset-inline-start: 7px;
          font-size: 12px;
          background-color: #fff;
        }
      }

      .phone-group {
        flex-direction: row;
        align-items: center;
      }

      .split-field {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        @media (max-width: $breakpoint-mobile) {
          flex-direction: column;
        }

        .field-group {
          width: 45%;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
          }
        }
      }

      .submit {
        background-color: #D5E05B;
        color: #000;
        padding: 0.75rem 2rem;
        border: none;
        border-radius: 5px;
        margin: 1rem;
        font-size: 15px;
        text-transform: capitalize;
        outline: none;
        font-weight: 600;
        cursor: pointer;
        -webkit-appearance: none;
      }
    }

    .sign-in-link {
      text-decoration: none;
      color: #50B848;
      background-color: transparent;
      border: none;
      font-size: 14px;
      padding: 0;
      margin: 0;
      font-weight: 600;
      padding-inline-start: 10px;
    }
  }
}