.virtual-tour-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 12px;
  }

  .subtitle {
    font-size: 14px;
    line-height: 18px;
    margin: 0;
    margin-bottom: 20px;
  }
}