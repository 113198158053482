@import "../App.scss";

.user-data {
  .toogle-switch-container{
    top:-15px;
    left:0;
    align-self: left;
    justify-self: left;
    width:90%;
  }

  .user-data-inner {
    padding: 2.5rem 0.5rem;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 3pt 6pt #00000047;
    border-radius: 5px;
    margin-top: 3rem;
    margin-bottom: 3rem;
    max-width: 780px;

    .questionnaire-btn,
    .dashboard-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px;
      padding: 10px;
      background-color: #d5e05b;
      border-radius: 5px;
      font-size: 16px;
      font-weight: 500;
      border: none;
      cursor: pointer;
      position: absolute;
      top: 0;

      @media (max-width: $breakpoint-mobile) {
        width: 150px;
        font-size: 16px;
        justify-content: center;
        padding: 10px;
      }

      .arrow-icon {
        -webkit-margin-end: 0.25rem;
        margin-inline-end: 0.25rem;
        height: 25px;
        width: auto;

        @media (max-width: $breakpoint-mobile) {
         display: none;
        }

      }
    }

    .questionnaire-btn {
      inset-inline-start: 0;
    }

    .dashboard-btn {
      inset-inline-end: 0;
    }

    .title {
      font-size: 28px;
      font-family: "Raleway", sans-serif !important;
    }

    .note {
      text-align: center;
      font-size: 14px;
    }

    .links-container {
      max-width: 600px;

      @media (max-width: $breakpoint-mobile) {
        width: 100%;
      }
    }

  }
  .file-upload label{
    margin:2px;
  }

.topRowButtons{
  display: flex;
  align-items: center;
  justify-content: center;
  width:100%;
  margin-bottom: 30px;
}
}
