@import '../App.scss';

.brand-manual-and-logos {
  display: flex;
  padding-top: 30px;
  margin: 0 auto;
  position: relative;
  width: 95%;
  margin-bottom: 5rem;

  @media (max-width: $breakpoint-mobile) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .card-container {
    margin-inline-end: 150px;
    text-align: center;

    @media (max-width: $breakpoint-tablet) {
      margin-inline-end: 60px;
    }

    @media (max-width: $breakpoint-mobile) {
      margin-inline-end: 0px;
      margin-bottom: 60px;
    }

    .image-area {
      border-radius: 15px;
      box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.64);

      img {
        width: auto;
        height: 270px;

        @media (max-width: $breakpoint-mobile) {
          width: 100%;
          height: auto;
        }
      }
    }

    .title {
      margin-top: 40px;
      font-weight: bold;
      font-size: 14px;
    }

    button.nd-btn {
      margin-top: 30px;
      cursor: pointer;
    }
  }
}
