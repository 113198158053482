@import "../../App.scss";

.social-box {
  .socials-title {
    text-align: center;
    color: #000000;
    font-size: 17px;
  }

  .register-form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .social-input-box {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 28%;
      padding: 1rem;
      position: relative;

      @media (max-width: $breakpoint-small-laptop) {
        width: 35%;
      }

      @media (max-width: $breakpoint-tablet) {
        width: 40%;
        padding: 1rem;
      }

      @media (max-width: $breakpoint-mobile) {
        width: 80%;
        padding: 0.5rem;
      }

      .field-top-label {
        margin: 0;
        font-size: 14px;
        color: #50b848;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        top: 0;
        inset-inline-start: 17px;

        @media (max-width: $breakpoint-mobile) {
          inset-inline-start: 10px;
          top: -3px;
        }

        .social-icon {
          height: 18px;
          width: 22px;
          -webkit-padding-end: 0.25rem;
          padding-inline-end: 0.25rem;
          transition: all 0.2s;
          color: #50b848;
        }
      }

      .field-group {
        display: flex;
        flex-direction: column;
        margin: 0.5rem 0;
        width: 100%;
        position: relative;

        .time {
          border: 1px solid #00000059;
          padding: 5px;
          margin-right: 7px;
          border-radius: 5px;
        }

        .MuiInput-underline:before {
          border: none;
        }

        .field,
        .PhoneInputInput {
          padding: 0.7rem;
          border-radius: 5px;
          font-size: 16px;
          border: 1px solid #00000059;
          outline: none;

          &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 30px white inset;
          }

          .MuiSelect-select {
            padding: 0;
            border: 0;

            &:focus {
              background-color: transparent;
            }
          }
        }

        .input-label {
          display: flex;
          align-items: center;
          position: absolute;
          top: 0.6rem;
          inset-inline-start: 0.2rem;
          overflow: hidden;
          color: var(--colorSecond);
          font-size: 16px;
          text-overflow: ellipsis;
          white-space: nowrap;
          transition: all 0.2s;
          pointer-events: none;

          .social-icon {
            height: 24px;
            width: 30px;
            -webkit-padding-end: 0.25rem;
            padding-inline-end: 0.25rem;
            transition: all 0.2s;
            color: #50b848;
          }
        }

        .field:focus + .input-label .social-icon {
          height: 18px;
          -webkit-padding-end: 0.05rem;
          padding-inline-end: 0.05rem;
        }

        .field:focus + .input-label,
        .field:not(:placeholder-shown) + .input-label {
          top: -10px;
          inset-inline-start: 8px;
          font-size: 12px;
          background-color: #fff;
        }
      }

      .half-group {
        flex-direction: row;
      }
    }

    .contact-days {
      text-align: center;
      color: #50b848;
      font-size: 14px;
    }

    .days-check-container {
      width: 100%;
      justify-items: center;
      top: 0;
    }

    .day-options-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  .submit {
    padding: 1rem 5rem;
    margin: 2rem auto;
    display: block;
    cursor: pointer;
    -webkit-appearance: none;

    @media (max-width: $breakpoint-small-laptop) {
      width: 40%;
    }

    @media (max-width: $breakpoint-mobile) {
      width: auto;
    }
  }
}