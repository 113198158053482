@import "../../App.scss";

.onboarding-progress {
  display: flex;
  max-width: 85%;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $breakpoint-small-laptop) {
    width: 65%;
  }

  @media (max-width: $breakpoint-tablet) {
    width: 85%;
  }

  .splitter {
    height: 2px;
    width: 70px;
    background-color: $blue-color;
    border: none;
    margin-top: -15px;

    @media (max-width: $breakpoint-tablet) {
      margin-top: -25px;
    }
  }

  .progress-step {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 110px;

    .progress-icon {
      position: absolute;
      top: 0;
      inset-inline-end: 0;
      width: 24px;
      height: 24px;
      color: $primary-color;
      background-color: #fff;
      border-radius: 20px;
    }

    .progress-digit {
      border: 3px solid $blue-color;
      margin: 0;
      border-radius: 50%;
      font-size: 32px;
      color: $blue-color;
      font-weight: 700;
      font-variant-numeric: tabular-nums;
      width: 70px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .progress-label {
      margin-bottom: 0;
      font-size: 12px;
      font-weight: 500;
      color: #2a6e9480;

      @media (max-width: $breakpoint-tablet) {
        text-align: center;
      }
    }
  }
}