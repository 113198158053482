@import '../App.scss';

.modal {
  color: $primary-black-color;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal__overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: $primary-black-color;
	opacity: 0.6;
	z-index: 10;
  }

  .modal__container {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 0;
	margin-right: 0;
	width: 100%;
	height: 100%;

	.modal__body {
	  position: relative;
	  margin: 8rem 0;
	  width: 100%;
	  max-width: 375px;
	  z-index: 20;

	  .modal__body-container {
		position: relative;
		background-color: $primary-white-color;
		min-height: 30vh;
		max-height: 65vh;
		overflow: hidden;
		border-radius: 5px;
		padding: 0 8px 8px;

		.modal-close {
		  position: sticky;
		  top: 0;
		  height: 20px;
		  width: 100%;
		  margin: 0;
		  padding: 8px 0;
		  background-color: $primary-white-color;
		  display: flex;
		  justify-content: flex-end;
		}

		.modal__content {
		  padding-right: 2px;
		  padding-left: 2px;
		  background-color: $primary-white-color;
		  color: $primary-black-color;
		  display: flex;
		  flex-direction: column;
		  justify-content: center;

		  * {
			margin: 0;
			padding: 0;
		  }

		  h3,
		  p {
			text-align: center;
			margin-bottom: 0.5rem;
		  }

		  .modal__countdown {
			display: flex;
			justify-content: center;
			margin-top: 20px;

			.minutes,
			.seconds {
			  margin-right: 10px;
			  font-size: 1.5rem;
			  font-weight: 500;
			  color: gray;

			  span {
				color: lighten(green, $amount: 15);
				font-size: 2.5rem;
				font-weight: bold;
			  }
			}

			.seconds {
			  margin-left: 10px;
			}

			.countdown-pointers {
			  width: 25px;
			  display: flex;
			  flex-direction: column;
			  justify-content: space-evenly;
			  align-items: center;

			  .point-1,
			  .point-2 {
				width: 5px;
				height: 5px;
				border-radius: 50%;
				background-color: gray;
			  }
			}
		  }

		  .modal__controls {
			margin-top: 10px;
			display: flex;
			width: 100%;
			justify-content: space-around;

			button {
			  font-size: 1rem;
			  font-weight: 600;
			  width: 45%;
			  border: none;
			  outline: none;
			  background-color: $primary-bg-color;
			  padding: 1rem 1.2rem;
			  border-radius: 5px;
			  cursor: pointer;
			}
		  }
		}
	  }
	}
  }
}
