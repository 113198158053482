$breakpoint-small-mobile: 400px;
$breakpoint-mobile: 576px;
$breakpoint-tablet: 768px;
$breakpoint-small-laptop: 992px;
$breakpoint-laptop: 1200px;
$breakpoint-laptops: 1500px;
$breakpoint-laptops-resolution: 1900px;
$container: 2rem 10rem;
$mobile-container: 1rem 1rem;
$laptop-container: 2rem 5rem;
$blue-color: #2A6E94;
$primary-color: #50B848;
$primary-bg-color: #D5E05B;
$primary-black-color: #000000;
$primary-white-color: #ffffff;
$ul-list-color: #FFBC00;





