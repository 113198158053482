@import "../App.scss";

.my-profile {
  display: flex;
  flex-direction: column;
  position: relative;

  .my-profile-inner {
    min-width: 250px;
    height: 100%;
    max-height: 220px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .name {
    font-size: 22px;
    margin: 0;
  }

  .master-badge {
    color: #fff;
    font-size: 10px;
    font-weight: 600;
    padding: 3px 9px;
    background-color: $primary-color;
    border-radius: 4px;
    text-transform: uppercase;
    margin: 0.15rem 0;
    letter-spacing: 0.5px;
  }

  .space-div {
    margin-top: 1rem;
  }

  .grid {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .grid-row {
      line-height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left;

      .column-1 {
        font-weight: 600;
        font-size: 15px;

        .tag {
          color: white;
        }
      }

      .column-2 {
        font-weight: 500;
        font-size: 14px;
      }

      .column-3 {
        font-weight: bold;
      }

      .column-4 {
        display: flex;
        align-items: center;
        color: #50b848;
        font-weight: bold;

        .down {
          color: red;
        }
      }
    }
  }
}

.market-analysis {
  display: flex;
  flex-direction: column;
  height: 295px;

  .name {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 20px;
  }

  .green-cta {
    padding: 12px 45px;
    width: 30%;
    font-size: 14px;
    font-weight: 700;
    @media (max-width: $breakpoint-laptops) {
      width: 33%;
    }
  }

  .list {
    margin-bottom: 10px;
    min-width: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 183px;

    &.extended {
      height: 240px;
    }

    .spinner {
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .grid-row {
      box-sizing: border-box;
      border-bottom: 1px solid #0000004d;
      margin: 2px;
      padding: 10px 0px;

      .title {
        display: flex;
        justify-content: space-between;
        color: #50b848;

        .unit {
          font-weight: 600;
          font-size: 14px;
        }

        .time {
          font-size: 10px;
          -webkit-padding-end: 10px;
                  padding-inline-end: 10px;
        }
      }

      .text {
        margin-top: 10px;
        font-size: 12px;
      }
    }
  }
}

.marketing-carousel {
  padding-top: 15px;
  display: flex;
  align-items: center;

  .slideshow {
    margin: 0 auto;
    overflow: hidden;

    .slideshowSlider {
      white-space: nowrap;
      transition: ease 1000ms;
      width: 100%;

      .slide {
        background-repeat: no-repeat;
        background-position: center;
        display: inline-block;
        border-radius: 3px;
        width: 100%;

        .slide-inner {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;

          .banner-image {
            border-radius: 3px;
          }

          .banner-text {
            text-align: center;
            width: 250px;
            white-space: pre-wrap;
          }
        }
      }
    }

    .slideshowDots {
      text-align: center;
    }

    .slideshowDot {
      display: inline-block;
      width: 5px;
      height: 5px;
      padding: 0;
      border: none;
      border-radius: 100%;
      cursor: pointer;
      margin: 12px 7px 0px;
      background-color: #000000;
      opacity: 0.5;
    }

    .slideshowDot.active {
      display: inline-block;
      width: 5px;
      height: 5px;
      padding: 0;
      border: none;
      border-radius: 100%;
      cursor: pointer;
      margin: 12px 7px 0px;
      background-color: #000000;
      opacity: 1;
    }
  }
}

.dummy-widget {
  width: 100%;
  text-align: center;
}

.tabs-container {
  width: 100%;

  .tab-headers {
    display: flex;
    border-bottom: 2px solid #7070704d;
    position: relative;
    width: 95%;
    margin: 0 auto;

    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
      align-items: center;
    }

    .tab-header {
      cursor: pointer;
      font-size: 16px;
      padding: 12px 30px;

      @media (max-width: $breakpoint-mobile) {
        padding: 10px 10px;
      }

      &.active-tab {
        text-shadow: 1px 0px 0px black;
      }
    }

    .tab-active-link-bar {
      position: absolute;
      width: 100px;
      height: 3px;
      background-color: #50b848;
      bottom: -2px;
      transition-property: width, left, right;
      transition-duration: 0.3s;

      @media (max-width: $breakpoint-mobile) {
        display: none;
      }
    }
  }

  .tab-content {
    padding: 10px;
    height: 100%;
    position: relative;
    width: 95%;
    margin: 0 auto;
  }
}

.marketing-video {
  .referralSelectors {
    display: flex;
    box-sizing: border-box;

    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
    }

    .selector {
      display: flex;
      flex-direction: column;
      margin: 1rem 2rem;

      @media (max-width: $breakpoint-mobile) {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0.3rem 0.1rem;
      }

      @media (max-width: $breakpoint-tablet) {
        margin: 0.3rem 1rem;
      }

      .close {
        opacity: 0.3;
      }

      .close:hover {
        opacity: 1;
        color: $primary-color;
      }

      .close:after {
        background-color: $primary-color;
        width: 13px;
        height: 13px;
        content: "x";
      }

      .optionsLabel {
        font-size: 14px;
        font-weight: 600;
        margin: 0.5rem 0;
        width: 10rem;
      }

      .optionsSelector {
        width: 175px;
        border: 2px solid #00000033;
        border-radius: 5px;
        outline: none;

        @media (max-width: $breakpoint-mobile) {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  .filter-section {
    display: flex;
    flex-direction: row;

    .filter {
      flex-basis: 235px;
      margin: 0 20px;

      .form-label {
        font-weight: bold;
        padding-bottom: 12px;
        font-size: 12px;
      }

      .MuiInputBase-root {
        width: 100%;
      }

      button.nd-btn {
        margin-top: 38%;
      }
    }
  }

  .content {
    padding: 20px;

    @media (max-width: $breakpoint-mobile) {
      padding: 2px;
    }

    .campaign-name {
      display: flex;
      align-items: center;

      @media (max-width: $breakpoint-mobile) {
        flex-direction: column;
        align-items: start;
        margin-top: 2rem;
      }

      .label {
        color: #50b848;
        font-weight: bold;
        font-size: 16px;
        -webkit-padding-end: 20px;
                padding-inline-end: 20px;
      }

      .campaign-name-input {
        display: flex;
        flex-direction: column;

        input {
          border: none;
          border-bottom: 1px solid black;
          margin: 15px 0 5px;
          font-size: 16px;

          &:focus,
          &:active,
          &:focus-visible {
            border: none;
            border-bottom: 1px solid black;
            outline: none;
          }
        }

        span {
          font-size: 12px;
          color: #00000088;

          svg {
            -webkit-padding-start: 10px;
                    padding-inline-start: 10px;
            color: #ff9800;
            font-size: 12px;
          }
        }

        .popover-content {
          background-color: #ffbc00;
          width: 250px;
          padding: 5px;

          &.-arrow::after {
            border-bottom-color: #ffbc00;
          }
        }
      }
    }

    @media (max-width: $breakpoint-mobile) {
      .video-card {
        min-height: 100%;
        padding: 10px;
        -webkit-margin-start: 0px;
                margin-inline-start: 0px;
        margin-bottom: 20px;
        box-shadow: 0px 3px 6px #00000029;
      }

      .txt-preview {
        -webkit-margin-start: 1px;
                margin-inline-start: 1px;
      }

      .nd-btn {
        padding: 12px 20px;
      }
    }
  }
}

.videos {
  padding-top: 40px;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: $breakpoint-mobile) {
    justify-content: center;
  }

  .video-card {
    padding: 10px;
    -webkit-margin-start: 20px;
            margin-inline-start: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 3px 6px #00000029;

    .button-video {
      cursor: pointer;
      background-color: transparent;
      border: none;

      &:hover {
        background-color: #d7e265;
        transform: scale(1.05);
      }
    }

    .video-loaded {
      transition: width ease 0.75s, height ease 0.75s, -webkit-filter ease 0.75s;
      transition: width ease 0.75s, height ease 0.75s, filter ease 0.75s;
      transition: width ease 0.75s, height ease 0.75s, filter ease 0.75s,
        -webkit-filter ease 0.75s;
    }

    .video-loading {
      -webkit-filter: blur(20px);
      filter: blur(20px);
    }

    img {
      width: 300px;
    }

    .nd-btn {
      cursor: pointer;
    }

    .bottom {
      display: flex;
      justify-content: space-between;

      .icon {
        color: #50b848;
        display: flex;
        align-items: center;
        font-weight: bold;

        .txt-preview {
          -webkit-margin-start: 10px;
                  margin-inline-start: 10px;
        }
      }
    }
  }
}

.img-banners {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  img {
    flex: 0 0;
    padding: 10px;
  }
}

.partner-widget {
  border: 1px solid #70707042;
  border-radius: 8px;
  padding: 35px;
  margin: 16px;
}

.your-partner-link {
  padding: 20px;

  .selectorBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    .pageLangSplitter {
      display: flex;
      justify-content: space-between;

      .splitterInnerBox {
        width: 100%;
      }

      .splitterInnerBox.secondBox {
        -webkit-margin-start: 0.75rem;
                margin-inline-start: 0.75rem;
      }
    }

    .promoCampaignCtaBox {
      display: flex;

      .optionsSelector {
        -webkit-margin-end: 0.75rem;
                margin-inline-end: 0.75rem;
      }

      .green-cta {
        -webkit-padding-end: 1.25rem;
                padding-inline-end: 1.25rem;
        -webkit-padding-start: 1.25rem;
                padding-inline-start: 1.25rem;
        cursor: pointer;
      }
    }

    .optionsSelector {
      margin: 0.5rem 0;
      width: 100%;
    }
  }

  .submitBtnContainer {
    margin-top: 20px;
  }

  .urlBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .urlSnippet {
      color: #50b848;
      padding: 1rem;
      border-radius: 5px;
      margin: 0.5rem 0;
      border: 2px solid #9898986b;
      max-width: 90%;
      font-size: 14px;
      word-wrap: break-word;

      @media (max-width: $breakpoint-mobile) {
        padding: 0.5rem;
      }
    }

    .ctaPromoBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 0.5rem;

      @media (max-width: $breakpoint-mobile) {
        display: unset;
      }

      .green-cta {
        padding: 15px 50px;
        cursor: pointer;
        font-weight: 700;
        font-size: 14px;
        margin: 0;
        max-width: 190px;
      }

      .promoBox {
        .text {
          font-size: 14px;
          font-weight: 600;
          margin: 0;

          @media (max-width: $breakpoint-mobile) {
            padding-top: 0.5rem;
          }
        }

        .code {
          color: $primary-color;
          border: 2px solid #9898986b;
          background-color: transparent;
          padding: 2px 10px;
          border-radius: 3px;
          font-size: 16px;
          font-weight: 500;
          margin: 0;
          margin-top: 5px;
          text-align: center;
        }
      }
    }
  }

  .title {
    font-size: 20px;
    margin: 0 0 12px;
    @media (max-width: $breakpoint-laptops) {
      font-size: 15px;
    }
  }

  .MuiInputBase-root.MuiOutlinedInput-root {
    -webkit-margin-end: 24px;
            margin-inline-end: 24px;
    margin-bottom: 24px;
    width: 150px;
  }

  .MuiSelect-root.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
    padding: 12px;
  }

  .sign-up-link {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    font-size: 14px;

    .text {
      font-size: 14px;
      font-weight: 600;
      margin: 0;
      @media (max-width: $breakpoint-laptops) {
        font-size: 12px;
      }
    }

    .warning-popover {
      font-size: 14px;
    }

    .MuiSvgIcon-root {
      color: #ff9800;
      font-size: 20px;
      -webkit-margin-start: 10px;
              margin-inline-start: 10px;
    }

    .popover-content {
      background-color: #ffbc00;
      width: 250px;
      padding: 5px;

      &.-arrow::after {
        border-bottom-color: #ffbc00;
      }
    }
  }

  input {
    margin: 20px 0;
    display: block;
    width: 100%;
    padding: 5px;
    color: #50b848;
  }

  .your-partner-selectors {
    display: flex;
    box-sizing: border-box;

    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
    }

    .partner-selector {
      display: flex;
      flex-direction: column;
      margin: 1rem 0.5rem 1rem 0rem;

      @media (max-width: $breakpoint-mobile) {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0.3rem 0.1rem;
      }

      @media (max-width: $breakpoint-tablet) {
        margin: 0.3rem 1rem;
      }

      .optionsLabel {
        font-size: 14px;
        font-weight: 600;
        margin: 0.5rem 0;
        width: 10rem;
      }

      .optionsSelector {
        padding: 0.75rem 1rem;
        width: 164px;
        border: 2px solid #00000033;
        border-radius: 5px;
        outline: none;

        @media (max-width: $breakpoint-mobile) {
          width: 100%;
          margin: 0;
        }
      }
    }
  }
}

.client-presets {
  padding-bottom: 20px!important;

  .title {
    margin: 0;
    font-size: 20px;
  }

  .text {
    margin: 0;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 15px;
  }

  .blockableWrapper {
    position: relative;
  }

  .field-splitter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: all ease 0.5s;

    .field-group {
      width: 48%;
    }
  }

  .cta-container {
    transition: all ease 0.5s;
  }

  .field-label {
    font-size: 14px;
    font-weight: 600;
    color: #000;
    margin-bottom: 0.25rem;
  }

  .selector {
    border: none;
    font-size: 14px;
    background-color: transparent;
    color: #000;
    border: 1px solid #0000004d;
    border-radius: 5px;
    padding: 0.5rem;
    outline: none;
    transition: color 1s, border-color 1s, border-width 0.2s, border-radius 1s;
    cursor: pointer;
  }
}

.campaign-request {
  padding-bottom: 20px !important;
  position: relative;
  display: flex;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }

  .content-wrapper > * {
    transition: -webkit-filter ease 0.75s;
    transition: filter ease 0.75s;
    transition: filter ease 0.75s, -webkit-filter ease 0.75s;
    filter: blur(0px);
    -webkit-filter: blur(0px);
  }

  .selectorBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 1;

    .MuiSvgIcon-root {
      color: #ff9800;
      font-size: 20px;
      -webkit-margin-start: 10px;
              margin-inline-start: 10px;
    }

    .popover-content {
      background-color: #ffbc00;
      width: 250px;
      padding: 15px;
      font-size: 12px;

      p {
        margin: 0;
      }

      &.-arrow::after {
        border-bottom-color: #ffbc00;
      }
    }

    .optionsSelector {
      margin: 0.5rem 0;
      width: 100%;
    }

    .campaign-option {
      display: flex;
      width: 100%;
      height: auto;
      justify-content: space-between;
      align-items: center;

      .status-text {
        padding: 10px 40px;
        border-radius: 5px;
        text-transform: uppercase;
        font-weight: 500;
        -webkit-margin-end: 1rem;
                margin-inline-end: 1rem;
        text-align: center;
        font-size: 8px;
      }
    }

    .campaign-option.pending {
      color: #cf9800;
    }
  }

  .submitBtnContainer {
    margin-top: 10px;
  }

  .urlBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .green-cta {
      padding: 15px 50px;
      cursor: pointer;
      font-weight: 700;
      font-size: 16px;
    }
  }

  .checkbox-label {
    font-size: 10px;
  }

  .terms-link {
    font-size: 10px;
    color: darkcyan;
  }

  .terms-input {
    margin: 5px auto;
  }

  .description-container {
    margin: 5px 5px 15px;
  }

  .campaign-description {
    font-size: 11px;
    margin: 0;
  }

  .info-icon {
    vertical-align: bottom;
  }

  .title {
    font-size: 20px;
    margin: 0 0 12px;
    @media (max-width: $breakpoint-laptops) {
      font-size: 15px;
    }
  }

  .MuiInputBase-root.MuiOutlinedInput-root {
    -webkit-margin-end: 24px;
            margin-inline-end: 24px;
    margin-bottom: 24px;
    width: 150px;
  }

  .MuiSelect-root.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
    padding: 12px;
  }

  input {
    margin: 20px 0;
    display: block;
    width: 100%;
    padding: 5px;
    color: #50b848;
  }

  .flex-row-div {
    display: flex;
    flex-direction: row;
  }

  .flex-row-center-div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .div-width-10 {
    width: 10%;
  }

  .div-width-5 {
    margin-inline-end: 5px;
    margin-inline-start: -21px;
  }
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 0.5rem;

  @media (max-width: $breakpoint-mobile) {
    display: unset;
  }
}

.qrCode-Modal {
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #ffffff54;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.6s ease, visibility 0.6s ease;
  visibility: visible;

  .qrCode-Modal-inner {
    padding: 3rem 2rem 1rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.91);
    box-shadow: 0 3pt 6pt #00000047;
    border-radius: 5px;
    justify-content: space-between;
    position: relative;

    .qrCode-close-icon {
      height: 30px;
      width: 30px;
    }

    .qrCode-Modal-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
      margin-bottom: 2rem;
    }

    .qrCode-Modal-title {
      font-size: 20px;
      margin: 0 0 12px;

      @media (max-width: $breakpoint-laptops) {
        font-size: 15px;
      }
    }

    .qrCode-Modal-text {
      font-size: 16px;

      @media (max-width: $breakpoint-mobile) {
        padding-top: 0.5rem;
      }
    }
  }
}
