@import 'App.scss';

.header {
  min-height: 850px;

  @media (max-width: $breakpoint-mobile) {
    min-height: 600px;
  }

  .header-inner {
    display: flex;
    flex-direction: row;
    padding-top: 10rem;
    justify-content: space-between;

    @media (max-width: $breakpoint-small-laptop) {
      align-items: center;
      padding-top: 3rem;
      flex-wrap: wrap;
    }

    .left {
      width: 50%;
      display: flex;
      flex-direction: column;

      @media (max-width: $breakpoint-mobile) {
        width: 100%;
      }

      .smart-text {
        min-height: 160px;

        .pre-title {
          font-size: 26px;
          font-weight: 600;
        }

        .title {
          font-size: 28px;
          font-weight: 600;
          margin: 0;

          @media (max-width: $breakpoint-mobile) {
            text-align: left;
            font-size: 28px;
            order: 1;
          }
        }

        .sub-title {
          font-size: 18px;
          font-weight: 600;
        }
      }

      .text {
        font-size: 16px;
        text-align: left;

        @media (max-width: $breakpoint-mobile) {
          text-align: center;
          font-size: 14px;
          order: 2;
        }
      }

      .ready {
        font-size: 20px;
      }

      .green-cta {
        padding: 15px 10px 15px 20px;

        @media (max-width: $breakpoint-mobile) {
          order: 4;
          margin-bottom: 3rem;
        }

        .cta-text {
          padding-inline-end: 20px;
        }
      }

      .logo {
        width: 270px;
        height: 100px;
        margin-top: 3rem;
        margin-bottom: 5rem;

        @media (max-width: $breakpoint-mobile) {
          width: 160px;
          height: 60px;
          order: 3;
          margin-top: 0;
          margin-bottom: 1rem;
        }
      }
    }

    .right {
      display: flex;
      justify-content: flex-end;

      .infographic {
        height: 550px;
        margin-inline-end: -150px;

        @media (max-width: $breakpoint-tablet) {
          height: 300px;
          margin-inline-end: -50px;
        }

        @media (max-width: $breakpoint-small-laptop) {
          height: 350px;
          margin-inline-end: -50px;
        }

        @media (max-width: $breakpoint-laptop) {
          height: 450px;
          margin-inline-end: -50px;
        }


        @media (max-width: $breakpoint-mobile) {
          height: auto;
          width: 100%;
          margin: 0;
        }
      }
    }
  }
}