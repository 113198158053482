.nd-profile-label {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 23px;
   --avatar-size: 2.5rem;

  .circle {
    background-color: #dddadafa;
    border-radius: 50%;
    height: var(--avatar-size);
    text-align: center;
    width: var(--avatar-size);
  }

  .initials {
    font-size: calc(var(--avatar-size) / 2);
    line-height: 1;
    position: relative;
    top: calc(var(--avatar-size) / 4);
    font-weight: 600;
  }

  img {
    width: 51px;
    height: 51px;
    border-radius: 50%;
    border: 1px solid #D5E05B;
  }

  span.nd-badge {
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: transparent linear-gradient(180deg, #D5E05B 0%, #50B848 100%) 0 0 no-repeat padding-box;
    top: 0;
    inset-inline-start: 20px;
  }

  .nd-name {
    font-size: 15px;
    font-weight: 500;

    .nd-name-p {
      margin: 0;
    }
  }

  .nd-name-section {
    padding-inline-start: 12px;
    font-size: medium;

    .nd-description {
      font-size: 12px;
    }
  }
}
