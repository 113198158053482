@import "../App.scss";

.form {
  .form-container {
    display: flex;
    position: relative;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 3pt 6pt #00000047;
    border-radius: 5pt;
    margin-bottom: 3rem;
    max-width: 700px;

    .title {
      font-size: 28px;
      margin: 0;
      padding: 1rem 0;
      text-align: center;
    }

    .form-content {
      padding: 10px 50px 50px 50px;
    }

    .flex-div {
      display: flex;
      flex-direction: row;
    }

    .empty-div {
      width: 30%;
    }

    .h5-style {
      padding-top: 1rem;
    }

    .subtitle-sm {
      font-size: 12px;
    }

    .subtitle {
      font-size: 16px;
      text-align: center;
      font-weight: 500;
      color: #50b848;
    }

    .yes-no-container {
      display: flex;
      justify-content: space-between;
      width: 25%;
    }

    .options-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .country-selector {
      width: 50%;
      border: 2px solid #00000033;
      border-radius: 5px;
      outline: none;
      min-height: 50px;
      -webkit-padding-start: 0.5rem;
      padding-inline-start: 0.5rem;

      @media (max-width: $breakpoint-mobile) {
        width: 100%;
      }
    }

    .sub-text {
      font-weight: 600;
    }

    .http-selector {
      min-height: 30px;
      padding: 10px;
      font-weight: 500;
      border: 2px solid #00000033;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      outline: none;
      background-color: #f8f8f8;
    }

    .input-fullWidth-container {
      width: 100%;
      min-height: 30px;
      padding: 10px;
      font-weight: 500;
      border: 2px solid #00000033;
      outline: none;
    }

    .website-border-radius {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .input-border-radius {
      border-radius: 5px;
    }

    .input-half-container {
      width: 48%;
      min-height: 30px;
      font-weight: 500;
      padding: 5px;
      border: 2px solid #00000033;
      border-radius: 5px;
      outline: none;
    }

    .optionLabel {
      color: #000000cc;
      font-size: 14px;
      font-weight: 500;
      display: flex;
    }

    .input {
      -webkit-margin-end: 0.5rem;
      margin-inline-end: 0.5rem;
    }

    .submit-btn {
      border: 0;
      font-weight: 600;
      min-width: 200px;
      min-height: 48px;
      background-color: #d5e05b;
      display: flex;
      justify-content: center;
      text-align: center;
      margin: 0 auto;
      margin-top: 40px;
      border-radius: 5px;
      cursor: pointer;
    }

    .flex-row-div {
      display: flex;
      flex-direction: row;
    }

    .flex-row-center-div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .div-margin-right {
      -webkit-margin-end: 1rem;
      margin-inline-end: 1rem;
      -webkit-margin-start: 0.5rem;
      margin-inline-start: 0.5rem;
    }

    .div-small-margin {
      -webkit-margin-end: 0.5rem;
      margin-inline-end: 0.5rem;
    }

    .checkbox-label {
      font-size: 10px;
    }

    .terms-link {
      font-size: 10px;
      color: darkcyan;
    }

    .terms-input {
      margin: 5px auto;
    }

    .description-container {
      margin: 5px;
      margin-bottom: 20px;
    }

    .campaign-description {
      font-size: 12px;
    }

    .info-icon {
      vertical-align: bottom;
    }

    .action-btn-container {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }

    .skip-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px;
      padding: 10px;
      background-color: #d5e05b;
      border-radius: 5px;
      font-size: 16px;
      font-weight: 500;
      border: none;
      cursor: pointer;
      inset-inline-end: 0;
      position: absolute;
      top: 0;

      .arrow-icon {
        -webkit-margin-end: 0.25rem;
        margin-inline-end: 0.25rem;
        height: 25px;
        width: auto;
      }
    }
  }
  .topRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    position: relative;
    width: 100%;
    padding: 20px;
    gap: 40px;
    flex-wrap: wrap;

    .skip-btn {
      overflow: auto;
      position: relative !important;
      justify-self: end !important;
      left: 50px !important;
    }
  }
}
.questionnaire {
  overflow: hidden;
}
