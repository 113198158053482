@import "App.scss";

.callback {
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.6s ease, visibility 0.6s ease;
  visibility: visible;

  .callback-inner {
    padding: 3rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 3pt 6pt #00000047;
    border-radius: 5pt;
    margin-top: 3rem;
    margin-bottom: 3rem;
    max-width: 500px;
    position: relative;

    .close-icon {
      height: 30px;
      width: 30px;
    }

    .title {
      font-size: 28px;
      margin: 0;
      padding-bottom: 1rem;
    }

    .callback-form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 350px;

      @media (max-width: $breakpoint-mobile) {
        width: 90%;
      }

      .field-group {
        display: flex;
        flex-direction: column;
        margin: 0.5rem 0;
        width: 100%;
        position: relative;

        input:-internal-autofill-selected {
          -webkit-box-shadow: 0 0 0 30px white inset !important;
        }

        .field-label {
          color: #7c7c7c;
          font-size: 12px;
        }

        .day-label {
          text-transform: capitalize;
        }

        .days-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .day-box {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            margin: 0.5rem 0;
            width: 30%;
          }
        }

        .field,
        .PhoneInputInput {
          padding: 0.7rem;
          border-radius: 5px;
          font-size: 16px;
          border: 1px solid #00000059;
          outline: none;

          &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
          }
        }

        .input-label {
          position: absolute;
          top: 0.8rem;
          inset-inline-start: 0.7rem;
          overflow: hidden;
          color: var(--colorSecond);
          font-size: 16px;
          text-overflow: ellipsis;
          white-space: nowrap;
          transition: all 0.2s;
          pointer-events: none;
        }

        .field:focus + .input-label,
        .field:not(:placeholder-shown) + .input-label {
          top: -6px;
          inset-inline-start: 7px;
          font-size: 12px;
          background-color: #fff;
        }
      }

      .phone-group {
        flex-direction: row;
        align-items: center;
        width: 100%;

        .PhoneInput {
          width: 100%;
        }
      }

      .selector {
        padding: 0.7rem;
        border-radius: 5px;
        font-size: 16px;
        border: 1px solid #00000059;
        outline: none;
      }

      .split-field {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .field-group {
          width: 45%;
        }
      }

      .submit {
        background-color: $primary-bg-color;
        color: #000;
        padding: 1rem 2rem;
        border: none;
        border-radius: 5px;
        font-size: 16px;
        text-transform: capitalize;
        outline: none;
        font-weight: 600;
        cursor: pointer;
        -webkit-appearance: none;
      }
    }
  }
}

.callback.hide-callback {
  opacity: 0;
  visibility: hidden;
}
