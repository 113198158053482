@import '../App.scss';

body[dir="rtl"] .nav-link {

  &:hover .text .title,
  &:hover .text .description {
    transform: translateX(-10%);
  }
}

.panel {
  display: flex;
  flex-direction: column;
  padding: 40px;
  width: 500px;

  @media (max-width: $breakpoint-tablet) {
    width: unset;
  }

  @media (max-width: $breakpoint-mobile) {
    width: unset;
  }

  .panel-title {
    font-size: 17px;
    font-weight: bold;
    align-self: center;
    margin-bottom: 20px;
  }

  .no-notifications-msg {
    text-align: center;
  }

  .modal-title-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .modal-title {
      font-size: 17px;
      font-weight: bold;
      align-self: center;
      margin-bottom: 20px;
      display: contents;
      justify-content: center;
    }

    .modal-bell-icon {
      -webkit-padding-end: 10px;
      padding-inline-end: 10px;
    }

    .sort-icon {
      width: 30px;
      height: 30px;
      color: #787878;
      cursor: pointer;
    }
  }

  .pagination-container {
    display: flex;
    justify-content: center;
    padding: 10px;
  }

  .horizontal-separator {
    border-bottom: 0.5px solid;
    color: lightgray;
    padding: 10px 0px;
  }

  .clip-loader-div {
    display: flex;
    justify-content: flex-end;
  }

  .notifications-loader-div {
    display: flex;
    justify-content: center;
    min-height: 455px;
    align-items: center;
  }

  .mark-read-text {
    font-size: 12px;
    color: #50B848;
    text-decoration: underline;
    text-align: left;
    cursor: pointer;
  }

  .see-more-text {
    font-size: 14px;
    color: #707070;
    text-align: center;
    cursor: pointer;
  }

  .date-field {
    font-size: 11px;
    color: #707070
  }

  .title-field-unread {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
  }

  .notification-row {
    display: flex;
    flex-direction: row;
    position: relative;
  }

  .customBadge {
    background-color: red;
  }

  .unread-icon {
    color: #50B848;
    height: 10px;
    width: 10px;
    position: absolute;
    left: -20px;
    top: 50%;
  }

  .notification-content-col {
    width: 80%
  }

  .notification-date-col {
    width: 20%;
    display: flex;
    justify-content: flex-end;
  }

  .title-field-read {
    font-size: 14px;
    margin-bottom: 0;
  }

  .description-field {
    font-size: 14px;
  }

  .description-field-unread {
    font-size: 14px;
    font-weight: 600;
  }

  .notifications-container {
    display: flex;
    min-height: 455px;
    flex-direction: column;
  }
}

.close-icon-container {
  display: flex;
  justify-content: flex-end;
}

.notifications-modal {
  .popup {
    .popup-inner {
      max-width: initial;
      width: auto;
      padding-bottom: 0;

      .sorting {
        position: absolute;
        inset-inline-end: 55px;
        display: flex;
        align-items: center;

        .label {
          font-size: 14px;
        }
      }
    }
  }
}